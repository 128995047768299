import React from 'react';
import VideoRequest from './components/VideoRequest';
import './index.css';

function App() {
    return (
        <div className="App">
            <main>
              <VideoRequest/>
            </main>
        </div>
    );
}

export default App;