import React, { useState } from 'react';

const VideoRequest = () => {
    const [isMainDone, setIsMainDone] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [isInError, setIsInError] = useState(false);
    const [redditLink, setRedditLink] = useState('');
    const [notification, setNotification] = useState('');
    const [errorData, setErrorData] = useState('');
    const [videoLink, setlink] = useState('');
    const [isInQueue, setIsInQueue] = useState(false);


    async function sendLink() {
        // TODO: change address to actual address
        try {
            const response = await fetch('/receive-link', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ link: redditLink }) 
            });
    
            if (!response.ok) {
                // Handle non-200 HTTP responses
                var errorData = await response.json();
                setErrorData(errorData.error || 'An unknown error occurred')

                console.error('Error during request:', errorData.error);
                setIsInProgress(false);
                setIsInError(true)  
            } else {
                // Handle success
                const data = await response.json();
                if (data.link){
                    setlink(data.link);
                    setIsMainDone(true);
                }
                else{
                    setIsInQueue(true);
                }

                setNotification('');
                setIsInError(false);
                setIsInProgress(false);
                
                console.log('Success:', data);
                

            }
        } catch (error) {
            // Handle network errors or other unforeseen errors
            console.error('Fetch error:', error);
            alert(`Cannot connect to server: ${error}`);
            setErrorData(error.message || 'An unknown error occurred')
            setIsInProgress(false);
            setIsInError(error.message || 'An unknown error occurred')
        }
    
    }
    

    const isValidRedditLink = async (url) => {
        const redditPostPattern = /^https:\/\/www\.reddit\.com\/r\/[^/]+\/comments\/[a-zA-Z0-9]+\/.+/;

    
        if (!redditPostPattern.test(url)) {
            console.log(`Invalid Reddit link format: ${url}`);
            return false;
        }

        return true;
    
        
    };
        
    const main = async () => {
        if (!redditLink) {
            setNotification('The Reddit link field cannot be empty.');
            return;
        }
        
        const isValid = await isValidRedditLink(redditLink);
        if (!isValid) {
            setNotification('The link is not a reddit post.');
            return;
        }
        else{
            try {
                setIsInProgress(true);

                // running __init__.py
                await sendLink()
            } catch (error) {
                console.error('Error during request:', error);
    
        }

        }
    };

    return (
        <section id="videoRequest">
            <img src="https://raw.githubusercontent.com/leciojor/videoGenData/main/logo512.png" className='logo' alt="Logo" />
            <h1 className='title'>Reddit Stories AI Video Generator</h1>
            <br/>
            <body> Use this plataform to generate a reddit post video to my channel :0</body>
            <br/>
            <h2> Add Reddit post link </h2>
            {notification && <div className='notification'>{notification}</div>}
            <input
                type="text"
                value={redditLink}
                onChange={(e) => setRedditLink(e.target.value)}
                placeholder="Enter Reddit post link"
                className='inputField'
            />
            {isInProgress ? (
                <div className='inprogress'>Video generation and posting is in progress... *this takes from 2 to 5 minutes</div>
            ) : (
                null
            )}
            {isMainDone ? (
                <div className='finishedLabel'>
                    Video generation and posting finished successfully...
                    <a href={videoLink} className='dataButton'>Video Link</a>
                </div>
            ) : (
                <button className='gatheringButton' onClick={() => main()}>Start Video generation and posting</button>
            )}
            {isInError ?  (
                <div className='errorLabel'>
                    Video generation and posting had an error: {errorData}. <br/>
                    Please try again...
                </div>
            ) : (
                null
            )

            }
            {isInQueue ?  (
                <div className='warningLabel'>
                    Youtube quota limit was reached but your video was generated. Your video was added to the queue and will be posted once it reaches your turn <br/>
                </div>
            ) : (
                null
            )

            }
        </section>
    );
};

export default VideoRequest;
